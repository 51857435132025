import axios from 'axios'
import { useToast } from 'vue-toastification'

const toast = useToast()
axios.defaults.headers.post['Content-Type'] = 'application/json'
const baseURL = process.env.VUE_APP_DOMAIN
// const PREFIX = '/devel'

const internalErrorMsg = 'Oops, something wrong. Please try again.'
const restrictedErrorMsg = 'You do not have permission to access this area.'

// const cookies = document.cookie
// const URL = baseURL + PREFIX
const BaseAxiosInstance = axios.create({ baseURL })
BaseAxiosInstance.interceptors.request.use(
  function (config) {
    // Remove incorrect headers
    config.headers.Accept = '*/*'
    config.headers['Content-Type'] = 'multipart/form-data'
    config.headers['Access-Control-Allow-Origin'] = '*'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

BaseAxiosInstance.interceptors.response.use(
  ({ data }) => (data.data ? data.data : data),
  async (error) => {
    if (error.code === 'ERR_NETWORK') {
      return toast.error('Something went wrong. Server not respond')
    }

    const errorData = error.response.data
    const errorStatus = error.response.status

    switch (errorStatus) {
      case 400:
        return toast.error(errorData.error || restrictedErrorMsg)
      /** Unauthorized **/
      case 401:
        return toast.error('Unauthorized')
      /** Forbidden **/
      case 403:
        toast.error(errorData.error || restrictedErrorMsg)
        window.location.replace('/')
        return toast.error(errorData.error || restrictedErrorMsg)
      /** Allowed statuses to propagation **/
      case 404:
        return toast.error(errorData.error || restrictedErrorMsg)
      /** Unhandled statuses **/
      default:
        return toast.error(error.error || internalErrorMsg)
    }
  }
)
export default BaseAxiosInstance
